export class Customer {
    id: string
    customer_id: string;
    beneficiary: string;
    beneficiary_phone: string;

    relationship_with_beneficiary: string;
    signing_mandate: string;
    other_account_holders: string;
    sales_person_id: string;
    clerk_id: string;
    supervisor_id: string;
    commencement_date: string;
    contribution_amount: string;
    type_of_remittance: string;
    remittance_date: string;
    esacalation: string;
    next_escalation: string;
    mode_of_payment: string;
    type_of_fund: string;
    currency: string;

    market_value: string;
    amount: string;

}
