import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {of} from 'rxjs';

export class BackendCommonUtil {

  public static handleHTTPError(error: HttpErrorResponse, router: Router) {
    if (error.status === 401) {
      localStorage.clear();
      router.navigate(['/auth/login']);
    } else {
      return of({
        success: false,
        message: error.message
      });
    }
  }

  public static createSuccessfulResponseForArray(response: any[], responseMapper: Function) {
    return {
      success: true,
      result: responseMapper(response)
    };
  }

  public static createSuccessfulResponse(response: any, responseMapper: Function) {
    if (response.success) {
      return {
        success: true,
        result: responseMapper(response)
      };
    } else {
      return {
        success: false,
        message: response.message
      };
    }
  }
}
