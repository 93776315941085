import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {BackendCommonUtil} from './backend-common.util';
import {Router} from '@angular/router';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

@Injectable()
export class DataExportService {

  private httpHeaders;

  constructor(private _http: HttpClient, private router: Router) {
    const token = JSON.parse(localStorage.getItem('user')).id;
    this.httpHeaders = new HttpHeaders({access_token: token});
  }

  public export(displayColumns, assets) {
    var options = {
      showLabels: true,
      headers: displayColumns
    };

    var assetArray = [];
    assets.forEach(asset => {
      var reordered = {}
      displayColumns.forEach(column => {
        if (typeof asset[column] == 'object' && asset[column] !== null){
          reordered[column] = asset.companyName? asset.companyName :asset[column].name;
        } else {
          reordered[column] = asset[column];
        }
        
      })
      assetArray.push(reordered)

    })


    new Angular5Csv(assetArray, Date.now().toString(), options);
  }
}
