import { Store } from '@ngrx/store';
import { CustomerStore } from '../../customer-store';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription, ReplaySubject, Subject } from 'rxjs';
import { corporateCustomerSelector } from '../../customer-selector';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import {
  ApproveCorporateCustomerAction,
  RejectCorporateCustomerAction,
  CreateCorporateCustomerAction,
  CreateCorporateContactAction
} from '../../actions/corporate-customer-actions';
import { CountryCode } from '../country-code';
import { BsModalService } from 'ngx-bootstrap';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { UserService } from '../../../../services/user.service';
import { FeedbackService } from '../../feedback.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fuse-corporate-customer-detail',
  templateUrl: './corporate-customer-detail.html',
  styleUrls: ['./corporate-customer-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CorporateCustomerDetailComponent implements OnInit, OnDestroy {
  selectedCustomer: any;
  currentUser: any;
  createCompany: any;
  showRejectReason = false;
  pageTitile = null;
  country;
  selectedIndex = 0;
  rejectReasonText = null;
  registrationCertificate = null
  taxRegistration = null
  PassportSizePhoto = null
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  private countryCodes: any = [];
  proofOfResidence = 'assets/images/file-images/file-img-1.png';
  IDTypeDoc = 'assets/images/file-images/file-img-2.png';
  passportSizePhoto = 'assets/images/file-images/file-img-3.png';
  payslip = 'assets/images/file-images/file-img-3.png';
  showRejectError = false;
  corporateCustomer = this.userService.companyForm;
  fileupload: any = [];
  private _ontitle = new Subject<void>();
  public countryCodesFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);
  public codesFiltr: FormControl = new FormControl();

  public filteredsales: ReplaySubject<any> = new ReplaySubject<any>(1);
  public salesFiltr: FormControl = new FormControl();

  private filtercodes() {
    if (!this.countryCodes.allCountries) {
      return;
    }
    let search = this.codesFiltr.value;
    if (!search) {
      this.countryCodesFiltered.next(this.countryCodes.allCountries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.countryCodesFiltered.next(
      this.countryCodes.allCountries.filter(countryCodes => countryCodes[0].toLowerCase().indexOf(search) > -1)
    );
  }

  constructor(
    private store: Store<CustomerStore>,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private fuseSplashScreen: FuseSplashScreenService,
    private feedbackService: FeedbackService,
    private _formBuilder: FormBuilder
  ) {
    this.countryCodes = new CountryCode();
    this.pageTitile = this.route.snapshot.params['data']
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user')).user;

    this.countryCodesFiltered.next(this.countryCodes.allCountries.slice());
    this.codesFiltr.valueChanges
      .pipe(takeUntil(this._ontitle))
      .subscribe(() => {
        this.filtercodes();
      });
  }

  corporateCustomerbody(customer) {
    const corporateCustomer =
    {
      "company_name": customer.company_name,
      "country": customer.country,
      "date_of_registration": customer.date_of_registration,
      "sector": customer.sector,
      "company_regisration_number": customer.company_regisration_number,
      "bank": customer.bank,
      "bank_account_number": customer.bank_account_number,
      "bank_account_name": customer.bank_account_name,
      "bank_branch": customer.bank_branch,
      "tax_id_number": customer.tax_id_number,
      "clerk": customer.clerk,
      "approval_status": 0
    }

    this.corporateCustomer.controls['id'].value == null
      ? this.createRecord(corporateCustomer)
      : this.updateRecord(corporateCustomer)
  }

  corCustomerContactBody(contactbody) {
    const contactcreate = {
      first_name: contactbody.contact.first_name,
      last_name: contactbody.contact.last_name,
      title: contactbody.contact.title,
      phone_number: contactbody.contact.phone_number,
      email: contactbody.contact.email,
      password: contactbody.contact.password,
      corporateCustomerId: contactbody.contact.corporate_customer_id
    }



    this.corporateCustomer.get('contact.id').value === null
      ? this.createcontact(contactcreate)
      : this.updatecontact(contactcreate)

  }

  createCorporateCustomer() {
    this.corporateCustomer.controls['clerk'].setValue(this.currentUser.id)
    this.corporateCustomerbody(this.corporateCustomer.getRawValue())
  }

  createRecord(customer) {
    this.fuseSplashScreen.show()
    this.userService
      .Post_Data('/corporateCustomers/', customer)
      .then(res => {
        if (res['success'] === true) {
          this.userService.companyForm.get('contact.corporate_customer_id').setValue(res['data'].id);
          this.corCustomerContactBody(this.corporateCustomer.getRawValue())
        } else {
          this.snackBar.open(res['message'], '', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.fuseSplashScreen.hide();
        }
        // this.fuseSplashScreen.hide();
      })
      .catch(eror => {
        this.snackBar.open('error occured! Please try again', '', {
          duration: 1000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.fuseSplashScreen.hide();
      });
  }

  updateRecord(corporateCustomer) {
    this.userService
      .Patch_Data('/corporateCustomers/' + this.corporateCustomer.controls['id'].value, corporateCustomer)
      .then(res => {
        if (res['success'] === true) {
          this.userService.companyForm.get('contact.corporate_customer_id').setValue(this.corporateCustomer.controls['id'].value);
          this.corCustomerContactBody(this.corporateCustomer.getRawValue())
        } else {
          this.snackBar.open(res['message'], '', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.fuseSplashScreen.hide();
        }
      })
      .catch(eror => {
        this.snackBar.open('error occured! Please try again', '', {
          duration: 1000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.fuseSplashScreen.hide();
      });
  }



  createcontact(contactbody) {
    this.userService
      .Post_Data('/contacts/addContact/', contactbody)
      .then(res => {
        if (res['success'] === true) {
          this.postFile(contactbody.corporateCustomerId);
        } else {
          this.snackBar.open(res['message'], '', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        }
        this.fuseSplashScreen.hide();
      })
      .catch(eror => {
        this.snackBar.open('error occured! Please try again', '', {
          duration: 1000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.fuseSplashScreen.hide();
      });
  }

  updatecontact(contactbody) {
    this.userService
      .Patch_Data('/contacts/' + this.corporateCustomer.get('contact.id').value, contactbody)
      .then(res => {
        if (res['success'] === true) {

          this.postFile(contactbody.corporateCustomerId);

        } else {
          this.snackBar.open(res['message'], '', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        }
        this.fuseSplashScreen.hide();
      })
      .catch(eror => {
        this.snackBar.open('error occured! Please try again', '', {
          duration: 1000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.fuseSplashScreen.hide();
      });
  }

  // createCorporateCustomer() {
  //   this.corporateCustomer.controls['clerk'].setValue(this.currentUser.id)
  //   this.store.dispatch(new CreateCorporateCustomerAction(this.corporateCustomer.value));
  // }



  selectedIndexChange(val: number) {
    this.selectedIndex += val;
  }

  createProductForm(): FormGroup {
    return this._formBuilder.group({
      id: this.selectedCustomer.id,
      title: this.selectedCustomer.contactId.title,
      firstName: this.selectedCustomer.contactId.firstName,
      phoneNumber: this.selectedCustomer.contactId.phoneNumber,
      lastName: this.selectedCustomer.contactId.lastName,
      email: this.selectedCustomer.contactId.email,
      companyName: this.selectedCustomer.companyName,
      sector: this.selectedCustomer.sector,
      dateOfRegistration: this.selectedCustomer.dateOfRegistration,
      companyRegistrationNumber: this.selectedCustomer
        .companyRegistrationNumber,
      taxIdNumber: this.selectedCustomer.taxIdNumber,
      bank: this.selectedCustomer.bank,
      bankAccountNumber: this.selectedCustomer.bankAccountNumber,
      bankAccountName: this.selectedCustomer.bankAccountName,
      bankBranch: this.selectedCustomer.bankBranch,
      salesPerson: this.selectedCustomer.salesPerson,
      clerk: this.selectedCustomer.clerk,
      supervisor: this.selectedCustomer.supervisor
    });
  }

  approveCorpCustomer(approval) {
    this.fuseSplashScreen.show();
    if (approval === 'approved') {
      this.userService
        .approveCorporateCustomer(this.corporateCustomer.controls['id'].value, this.currentUser.id)
        .subscribe(res => {
          if (res.success) {
            this.feedbackService.show('Approved', '', 'right', 'top');
            this.fuseSplashScreen.hide();
            this.router.navigate(['/auth/corporate-customers']);
          } else {
            this.feedbackService.show(res.message, '', 'right', 'top');
            this.fuseSplashScreen.hide();
          }
        });
    } else {
      const reason = approval;
      if (reason && reason.length > 0) {
        this.userService
          .rejectCorporateCustomer(this.corporateCustomer.controls['id'].value, this.currentUser.id, reason)
          .subscribe(res => {
            if (res.success) {
              this.feedbackService.show('Rejected', '', 'right', 'top');
              this.fuseSplashScreen.hide();
              this.router.navigate(['/auth/corporate-customers']);
            } else {
              this.fuseSplashScreen.hide();
              this.feedbackService.show(res.message, '', 'right', 'top');
            }
          });
      } else {
        this.showRejectError = true;
        this.fuseSplashScreen.hide();
      }
    }
  }

  openInput(id) {
    document.getElementById(id).click();
  }
  
  attachmentDownload(type) {
    window.open('http://13.127.79.37:3000/api/corporateCustomers/downloadCorporateCustomerFile?customerId=' + this.corporateCustomer.controls['id'].value + '&fileType=' + type);
  }

  attachmentUpload(event, docType) {
    const attachedFiles = event.target.files;
    if (attachedFiles) {
      const index = this.fileupload.findIndex(doc => doc.docType === docType);
      if (index > -1) {
        this.fileupload.splice(index, 1);
      }
      this.fileupload.push({ docType: docType, file: attachedFiles[0] })

      switch (docType) {
        case 'attachmentOfRegistrationCertificate':
          this.registrationCertificate = attachedFiles.item(0).name;
          break;
        case 'attachmentOfTaxRegistration':
          this.taxRegistration = attachedFiles.item(0).name;
          break;
        case 'attachmentOfPassportSizePhoto':
          this.PassportSizePhoto = attachedFiles.item(0).name;
          break;
      }
    }
  }

  postFile(customerId) {
    if (this.fileupload.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < this.fileupload.length; i++) {
        formData.append(this.fileupload[i].docType, this.fileupload[i].file);
      }
      this.userService
        .Post_Data('/corporateCustomers/uploadDocument?corporateCustomerId='
          + customerId + '&fileType=', formData)
        .then(res => {
          if (res['success'] === true) {
            this.feedbackService.show('Created a customer successfully', '', 'right', 'top');
            this.router.navigate(['/auth/corporate-customers']);
          } else {
            this.snackBar.open(res['message'], '', {
              duration: 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition
            });
          }
          this.fuseSplashScreen.hide();
        })
        .catch(eror => {
          this.snackBar.open('error occured! Please try again', '', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.fuseSplashScreen.hide();
        });
    } else {
      this.feedbackService.show('Created a customer successfully', '', 'right', 'top');
      this.router.navigate(['/auth/corporate-customers']);
    }
  }

  rejectCustomer() {
    this.showRejectReason = true;
  }

  cancelRejection() {
    this.showRejectError = false;
    this.showRejectReason = false;
    this.rejectReasonText = null;
  }

  ngOnDestroy(): void {
    //  this.subscription.unsubscribe();
  }
}
