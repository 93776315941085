import {Action} from '@ngrx/store';

export const FETCH_CORPORATE_CUSTOMERS = 'FETCH_CORPORATE_CUSTOMERS';
export class FetchAllCorporateCustomersAction implements Action {
  readonly type: string = FETCH_CORPORATE_CUSTOMERS;
  constructor() {
  }
}

export const LOAD_CORPORATE_CUSTOMERS = 'LOAD_CORPORATE_CUSTOMERS';
export class LoadCorporateCustomersAction implements Action {
  readonly type: string = LOAD_CORPORATE_CUSTOMERS;
  constructor(public payload: any[]) {
  }
}

export const DELETE_CORPORATE_CUSTOMER = 'DELETE_CORPORATE_CUSTOMER';
export class DeleteCorporateCustomerAction implements Action {
  readonly type: string = DELETE_CORPORATE_CUSTOMER;
  constructor(public payload: string) {
  }
}

export const DELETE_AND_RELOAD_CORPORATE_CUSTOMER = 'DELETE_AND_RELOAD_CORPORATE_CUSTOMER';
export class DeleteAndReloadCorporateCustomerAction implements Action {
  readonly type: string = DELETE_AND_RELOAD_CORPORATE_CUSTOMER;
  constructor(public payload: string) {
  }
}

export const CREATE_CORPORATE_CONTACT = 'CREATE_CORPORATE_CONTACT';
export class CreateCorporateContactAction implements Action {
  readonly type: string = CREATE_CORPORATE_CONTACT;
  constructor(public payload: any) {
  }
}

export const LOAD_NEW_CONTACT_ID = 'LOAD_NEW_CONTACT_ID';
export class LoadNewContactIdAction implements Action {
  readonly type: string = LOAD_NEW_CONTACT_ID;
  constructor(public payload: any) {
  }
}

export const CREATE_CORPORATE_CUSTOMER = 'CREATE_CORPORATE_CUSTOMER';
export class CreateCorporateCustomerAction implements Action {
  readonly type: string = CREATE_CORPORATE_CUSTOMER;
  constructor(public payload: any) {
  }
}

export const APPROVE_CORPORATE_CUSTOMER = 'APPROVE_CORPORATE_CUSTOMER';
export class ApproveCorporateCustomerAction implements Action {
  readonly type: string = APPROVE_CORPORATE_CUSTOMER;
  constructor(public payload: any) {
  }
}

export const REJECT_CORPORATE_CUSTOMER = 'REJECT_CORPORATE_CUSTOMER';
export class RejectCorporateCustomerAction implements Action {
  readonly type: string = REJECT_CORPORATE_CUSTOMER;
  constructor(public payload: any) {
  }
}

export const SELECT_CORPORATE_CUSTOMER = 'SELECT_CORPORATE_CUSTOMER';
export class SelectCorporateCustomerAction implements Action {
  readonly type: string = SELECT_CORPORATE_CUSTOMER;

  constructor(public payload: any) {
  }
}



