import {Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {CustomerStore} from '../customer-store';
import {FetchAllCorporateCustomersAction} from '../actions/corporate-customer-actions';
import {fuseAnimations} from '../../../../../../@fuse/animations';

import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services';

@Component({
  selector: 'fuse-corporate-customer',
  templateUrl: './corporate-customer.html',
  styleUrls: ['./corporate-customers.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CorporateCustomerComponent implements OnInit, OnDestroy {
  role;
  modalRef: BsModalRef;
  config = {
    class: 'modal-lg',
    animated: true
  };
  // currentUser;

  // constructor(private store: Store<CustomerStore>, private modalService: BsModalService) {
  //   const currentUser = JSON.parse(localStorage.getItem('user'));
  //   this.role = currentUser.user.role.name.toLowerCase();
  // }

  constructor(private store: Store<CustomerStore>, private router: Router , private userService: UserService) {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.role = currentUser.user.role.name.toLowerCase();
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchAllCorporateCustomersAction());
  }

  // newCorporateContact(template: TemplateRef<CorporateCustomerCreateComponent>) {
  //   this.modalService.show(template, this.config);
  // }

  
  newCorporateContact() {
    this.userService.companyForm.reset();
    this.userService.companyForm.enable();
    this.router.navigate(['/auth/corporate-customers-details' , {data : 'Create'}]);
  }

  ngOnDestroy(): void {
  }

}
