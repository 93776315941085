import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";

import { Subscription } from "rxjs";

import { fuseAnimations } from "@fuse/animations";
import { ContactsService } from "./customers.service";
import { CustomerService } from "../../services/customer.service";
import { Router } from "@angular/router";
import { CustomerStore } from "./customer-store";
import { Store } from "@ngrx/store";
import { FetchAllCustomersAction } from "./actions/customer-actions";
import { CustomerProfileSettingsComponent } from "./customer-form/customer-form.component";
import { FuseSplashScreenService } from "../../../../../@fuse/services/splash-screen.service";
import { UserService } from "../../services";
import { DataExportService } from "../../services/data-export.service";
import { UploadService } from "../../services/upload.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { number } from "currency-codes";

@Component({
  selector: "fuse-contacts",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FuseCustomerComponent implements OnInit {
  currentUser;
  importing = false;
  selectedFiles: FileList;
  selectedCustomers = [];

  progress: { percentage: number } = { percentage: 0 };
  constructor(
    private customerService: CustomerService,
    private exportService: DataExportService,
    private _uploadService: UploadService,
    public dialog: MatDialog,
    private userservice: UserService,
    private router: Router,
    private store: Store<CustomerStore>
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("user")).user;
  }

  newContact() {
    this.userservice.customerForm.enable();
    this.userservice.customerForm.reset();
    this.router.navigate(["/auth/customers-form", { data: "Create" }]);
  }

  ngOnInit() {
    this.customerService.GetSettingListFromService();
    this.store.dispatch(new FetchAllCustomersAction());
  }

  addToSelectedCustomers(_customer) {
    _customer.selected
      ? this.selectedCustomers.push(_customer)
      : this.selectedCustomers.splice(
        this.selectedCustomers.indexOf(_customer),
        1
      );
    console.log(_customer);
  }

  approveCustomers() {
    var approvalCount = 0;
    var customerIds = [];
    this.selectedCustomers.forEach(customer => {
      customerIds.push(customer.id);
    });
    this.userservice
      .approveCustomers(customerIds, this.currentUser.id)
      .subscribe(response => {
          console.log("success", response);
          this.customerService.GetSettingListFromService();
          this.store.dispatch(new FetchAllCustomersAction());
          this.selectedCustomers.splice(0, this.selectedCustomers.length);
          
      });
  }

  // rejectCustomer() {
  //   const reason = approval;
  //   if (reason && reason.length > 0) {
  //     this.userservice
  //       .rejectCustomer(
  //         this.contactsService.customerDetails.id,
  //         reason,
  //         this.currentUser.id
  //       )
  //       .subscribe(r => {
  //         if (r.success) {
  //           this.feedbackService.show("Rejected", "", "right", "top");
  //           this.router.navigate(["/auth/customers"]);
  //         } else {
  //           this.feedbackService.show(r.message, "", "right", "top");
  //         }
  //       });
  //   } else {
  //     this.showRejectError = true;
  //   }
  // }

  exportToExcel() {
    var columns = [
      'title',
      'lastName',
      'firstName',
      'gender',
      'IDType',
      'nationalIDCardNumber',
      'nationality',
      'homeAddress',
      'postalAddress',
      'phoneNumber',
      'emailAddress',
      'DOB',
      'employer',
      'employeeNumber',
      'nextOfKin',
      'nextOfKinPhone',
      'nextOfKinIDNumber',
      'relationshipWithNextOfKin',
      'salesPerson',
      'fundName',
      'contributionMethod',
      'contributionAmount',
      'commencementDate',
      'sourceOfIncome',
      'occupation',
      'transferDate',
      'bank',
      'bankAccountNumber',
      'bankAccountName',
      'bankBranch',
      'salesPersonIDNumber',
      'fundNumber',
    ];

    this.customerService.getAccountlist.subscribe(res => {
      console.log("customers", res);
      this.exportService.export(columns, res);
    });
  }

  //Import Functionality

  selectFile(event, filename, uploadType) {
    this.selectedFiles = event.target.files;
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    console.log(uploadType);
    if (uploadType == "customerUpload") {
      this.upload(filename, uploadType, this.selectedFiles.item(0));
    }
  }

  getFileExtension(filename) {
    console.log(filename);
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  upload(filename, uploadType, file) {
    this.importing = true;
    this.progress.percentage = 0;
    //var entityId = this.object.id;
    this._uploadService
      .pushFileToStorage(file, filename, uploadType)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(
            (100 * event.loaded) / event.total
          );
        } else if (event instanceof HttpResponse) {
          console.log(event.body);

          this.customerService.GetSettingListFromService();
          this.store.dispatch(new FetchAllCustomersAction());
          this.importing = false;
          // this._loginService.updateUser(user)
        }
      });

    this.selectedFiles = undefined;
  }
}
