import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CdkTableModule } from '@angular/cdk/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatGridListModule,
  MatListModule,
  MatProgressBarModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { AuthGuard } from '../../services';
// import { FuseContactsMainSidenavComponent } from './sidenavs/main/main.component';
import { FuseCustomerComponent } from './customers.component';
import { ContactsService } from './customers.service';
import { CustomerService } from "../../services/customer.service";
import { DataExportService } from "../../services/data-export.service";
import {
  FuseContactsCustomerListComponent
} from './customer-list/customer-list.component';
// import { FuseContactsSelectedBarComponent } from './selected-bar/selected-bar.component';
import { ModalModule } from 'ngx-bootstrap';
import { CustomerProfileSettingsComponent } from './customer-form/customer-form.component';
import { StoreModule } from '@ngrx/store';
import { corporateCustomerReducer, customerReducer } from './reducer/customer-reducer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerEffect } from './effects/customer-effect';
import { FeedbackService } from './feedback.service';
import { CorporateCustomerComponent } from './corporate/corporate-customer.component';
import { CorporateCustomerListComponent } from './corporate/list/corporate-customer-list.component';
import { CorporateCustomerEffect } from './effects/corporate-customer-effect';
import { CorporateCustomerDetailComponent } from './corporate/details/corporate-customer-detail.component';



const routes: Routes = [
  {
    path: 'customers',
    canActivate: [AuthGuard],
    component: FuseCustomerComponent
  },
  {
    path: 'corporate-customers',
    canActivate: [AuthGuard],
    component: CorporateCustomerComponent
  },
  {
    path: 'corporate-customers-details',
    canActivate: [AuthGuard],
    component: CorporateCustomerDetailComponent,
  },
  {
    path: 'customers-form',
    canActivate: [AuthGuard],
    component: CustomerProfileSettingsComponent
  },
];


export const customerReducers = {
  customers: customerReducer,
  corporateCustomers: corporateCustomerReducer
};

@NgModule({
  declarations: [
    FuseCustomerComponent,
    FuseContactsCustomerListComponent,
    CustomerProfileSettingsComponent,
    CorporateCustomerComponent,
    CorporateCustomerListComponent,
    CorporateCustomerDetailComponent
  ],
  imports: [
    AccordionModule.forRoot(),
    RouterModule.forChild(routes),
    CdkTableModule,
    ModalModule.forRoot(),
    MatButtonModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatCardModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatListModule,
    MatProgressBarModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule, MatSelectModule,
    MatDividerModule, MatRadioModule, MatTabsModule, MatStepperModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatSidenavModule,
    MatTableModule,
    MatToolbarModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatSnackBarModule,
    StoreModule.forFeature('customer', customerReducers),
    EffectsModule.forFeature([CustomerEffect, CorporateCustomerEffect])
  ],
  providers: [
    ContactsService, FeedbackService, CustomerService, DataExportService
  ],
  entryComponents: [
    CustomerProfileSettingsComponent,
    CorporateCustomerDetailComponent]
})
export class FuseCustomerModule {
}
