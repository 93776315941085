import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpRequest
} from "@angular/common/http";
import { environment } from "environments/environment";
@Injectable({
  providedIn: "root"
})
export class UploadService {
  constructor(private _http: HttpClient) {}

  pushFileToStorage(file: File, filename, type): Observable<HttpEvent<{}>> {
    let formdata: FormData = new FormData();

    formdata.append("file", file);

    formdata.append("type", type);
    formdata.append("filename", filename);

    const req = new HttpRequest(
      "POST",
      environment.serverURL + "/uploads/upload",
      formdata,
      {
        reportProgress: true,
        responseType: "text"
      }
    );

    return this._http.request(req);
  }
}
