import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource,   PageEvent
} from '@angular/material';
import {Store} from '@ngrx/store';
import {CustomerStore} from '../../customer-store';
import {corporateCustomerSelector} from '../../customer-selector';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {Subscription} from 'rxjs';
import {DeleteCorporateCustomerAction} from '../../actions/corporate-customer-actions';
import {FuseSplashScreenService} from '../../../../../../../@fuse/services/splash-screen.service';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {BsModalService} from 'ngx-bootstrap';

import {SelectCorporateCustomerAction} from '../../actions/corporate-customer-actions';
import {CorporateCustomerDetailComponent} from '../details/corporate-customer-detail.component';
import {UserService} from '../../../../services';
import {FeedbackService} from '../../feedback.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'fuse-corporate-customer-list',
  templateUrl: './corporate-customer-list.html',
  styleUrls: ['./corporate-customer-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CorporateCustomerListComponent implements OnInit, OnDestroy {
  allCustomers: any[];
  data: any;
  status = this.userService.status;
  pageEvent: PageEvent;
  currentUser: any;
  rowDef = [];
  headerRowDef = [];
  // pageSizeOptions = [5, 10, 15, 20];
  // length: number;
  // pageSize: number;
  // currentUser: any;
  // rowDef = [];
  // headerRowDef = [];


  private subscription: Subscription;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  selectedId;

  private config = {
    class: 'modal-lg',
    animation: true
  };

  constructor(private store: Store<CustomerStore>,
              private fuseSplashScreen: FuseSplashScreenService,
              private userService: UserService,
              private route: Router,
              private dialog: MatDialog) {
                this.currentUser = JSON.parse(localStorage.getItem('user')).user;
                if (this.currentUser.role.name === 'clerk') {
                  this.rowDef = ['companyName', 'corporatecustomername', 'contact', 'buttons' ];
                  this.headerRowDef = ['companyName', 'corporatecustomername', 'contact', 'buttons' ];
                } else {
                  this.rowDef = ['companyName', 'corporatecustomername', 'contact' , 'status'];
                  this.headerRowDef = ['companyName', 'corporatecustomername', 'contact' , 'status'];
                }
  //  this.fuseSplashScreen.show();
  }

  ngOnInit(): void {
    this.subscription = this.store.select(corporateCustomerSelector).subscribe(c => {
      this.data = new MatTableDataSource<any>(c.stored);
      this.data.paginator = this.paginator;
      this.data.sort = this.sort;
      this.data.length = c.stored.length;
      this.allCustomers = c.stored;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.data.filter = filterValue;
  }

  editCorporateCustomer(customer: any) {
    this.userService.companyForm.patchValue(customer);
    this.userService.companyForm.enable();
    this.route.navigate(['/auth/corporate-customers-details' , {data : 'Update'}]);
  }

  deleteCorporateCustomer(customer: any) {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.store.dispatch(new DeleteCorporateCustomerAction(customer.id));
      }
      this.confirmDialogRef = null;
    });
    this.fuseSplashScreen.hide();
  }

  // region created by shahrukh ebiztrait

  viewCorporateCustomer(corporateCustomer , title) {
    this.route.navigate(['/auth/corporate-customers-details' , {data : title}]);
    this.userService.companyForm.patchValue(corporateCustomer);
    this.userService.companyForm.disable();
  }

  customerDetails: any = {};
  accountid: any;
  currentBalance: any;// end of region

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
