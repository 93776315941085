import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BackendCommonUtil} from './backend-common.util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';


@Injectable()
export class CustomerService {
  customerDetails: any = {};
  private httpHeaders;
  transactionInitForm: FormGroup;
  getAccountlist: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private _http: HttpClient, private _router: Router, private formBuilder: FormBuilder, public snackBar: MatSnackBar) {
    this.setupHeader();
    this.transactionInitForm = this.formBuilder.group({
      customer: ['', Validators.required],
      account: ['', Validators.required],
      type: ['', Validators.required],
      amount: ['', Validators.required],
      currency: ['', Validators.required],
      fund: [''],
      destination_account: [''],
      reference: [''],
      customerType:[''],
      date:['']
    });
  }
  getToken() {
    // return localStorage.getItem('token');
    return localStorage.getItem('token');
  }
  postHttp_Header() {
    const httpOptions = {
      headers: new HttpHeaders({
        'access_token': this.getToken(),
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  GetSettingListFromService() {
    this.getAccountlist.next([])
    this.Post_Data('customers/')
    .then(res => {
      if (res !== undefined) {
        console.log(res)
        
        this.getAccountlist.next(res);
      } else {

      }
    });
  }
  Post_Data(url: any) {
    const options = this.postHttp_Header();
    // console.log(`option${options},body${body}`);

    return new Promise((resolve, reject) => {
      this._http.get(environment.serverURL + '/' + url, options).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    }).catch(err => {

    });
  }
  public createTransaction(transaction): Observable<any> {
    const request = this.adaptTransactionRequest(transaction);
    return this._http.post(environment.serverURL + '/customerTransactions/', request, {headers: this.httpHeaders}).pipe(
      map((res: any) => {
        res.success = true;
        return BackendCommonUtil.createSuccessfulResponse(res, this.createTransactionResponseMapper);
      }),
      catchError(err => BackendCommonUtil.handleHTTPError(err, this._router))
    );
  }

  public getAllTransactionRequests(): Observable<any> {
    this.setupHeader();
    return this._http.get(environment.serverURL + '/customerTransactions/', {headers: this.httpHeaders}).pipe(
      map((resp: any[]) => BackendCommonUtil.createSuccessfulResponseForArray(resp, this.adaptTransactionListResponse)),
      catchError(err => BackendCommonUtil.handleHTTPError(err, this._router))
    );
  }

  private adaptTransactionListResponse(resp: any[]) {
    return resp.map(r => {
      return {
        customerId: r.customer_id,
        accountId: r.customer_account_id,
        reference: r.reference,
        date: r.date,
        amount: r.amount,
        status: r.approval_status,
        type: r.transaction_type,
        fund: r.fund,
        id: r.id
      };
    });
  }

  public approve(customerId, accountId, transactionId , supervisor): Observable<any> {
    const request = {
      customerId: customerId,
      transactionId: transactionId,
      customerAccountId: accountId,
      supervisor_id: supervisor,
    };
    return this._http.post(environment.serverURL + '/customerTransactions/approvalTransaction', request, {headers: this.httpHeaders}).pipe(
      map(res => BackendCommonUtil.createSuccessfulResponse(res, this.adaptApprovalResponse)),
      catchError(err => BackendCommonUtil.handleHTTPError(err, this._router))
    );
  }

  private adaptApprovalResponse(response) {
    return response.message;
  }

  public reject(customerId, transactionId, rejectReason , supervisor): Observable<any> {
    const request = {
      customerId: customerId,
      customerTransactionId: transactionId,
      reason: rejectReason,
      supervisor_id: supervisor
    };
    return this._http.post(environment.serverURL + '/customerTransactions/rejectTransaction', request, {headers: this.httpHeaders}).pipe(
      map(res => BackendCommonUtil.createSuccessfulResponse(res, this.adaptApprovalResponse)),
      catchError(err => BackendCommonUtil.handleHTTPError(err, this._router))
    );
  }

  private adaptTransactionRequest(transaction) {
    //const date = new Date();
    return {
      customer_id: transaction.customer,
      reference: transaction.reference,
      date: transaction.date,
      amount: transaction.amount,
      transaction_type: transaction.type,
      destination_account: transaction.destination_account,
      fund: transaction.fund,
      customer_account_id: transaction.account,
      customer_type: transaction.customerType
    };
  }

  private createTransactionResponseMapper(response) {
    return {
      customerId: response.customer_id,
      accountId: response.customer_account_id,
      reference: response.reference,
      date: response.date,
      amount: response.amount,
      approvalStatus: response.approval_status,
      type: response.transaction_type,
      id: response.id
    };
  }

  private setupHeader() {
    const loggedInUser = localStorage.getItem('user');
    if (!loggedInUser) {
      this._router.navigate(['/auth/login']);
    } else {
      const token = JSON.parse(loggedInUser).id;
      this.httpHeaders = new HttpHeaders({access_token: token});
    }
  }
}
