import { Component, Inject, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { UserService } from "../../../services/user.service";
import { CountryCode } from "./country-code";
import { ContactsService } from "../customers.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar
} from "@angular/material";
import { BankService } from "../../../services/bank.service";
import { FeedbackService } from "../feedback.service";
import { Subject, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HttpClient, HttpResponse, HttpEventType } from "@angular/common/http";

@Component({
  selector: "fuse-customer-profile-settings",
  templateUrl: "./customer-form.component.html",
  styleUrls: ["./customer-form.scss"],
  animations: fuseAnimations
})
export class CustomerProfileSettingsComponent implements OnInit {
  step = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  ShowapproveButtons = true;
  rejectClicked = false;
  rejectReasonText = "";
  productForm: FormGroup;
  selectedIndex = 0;
  title = ["Dr", "Mr", "Mrs", "Ms", "Miss", "Mx", "Master", "Maid", "Madam"];
  IDType = [
    { name: "National Registration Card", value: "NRC" },
    { name: "Passport", value: "Passport" },
    { name: `Driver's License`, value: "DriverLicense" }
  ];
  private _ontitle = new Subject<void>();
  public filteredtitle: ReplaySubject<any> = new ReplaySubject<any>(1);
  public titleFiltr: FormControl = new FormControl();

  public countryCodesFiltered: ReplaySubject<any> = new ReplaySubject<any>(1);
  public codesFiltr: FormControl = new FormControl();

  public filteredidType: ReplaySubject<any> = new ReplaySubject<any>(1);
  public idtypeFiltr: FormControl = new FormControl();

  // public onClose: BehaviorSubject<any>;
  showRejectError = false;
  customerId = null;
  isNewCustomer = true;
  selectedCustomer = null;
  currentUser: any;
  customerForm = this.userService.customerForm;
  countryCodes: any;
  files: FileList;
  proofOfResidence = "assets/images/file-images/file-img-1.png";
  IDTypeDoc = "assets/images/file-images/file-img-2.png";
  passportSizePhoto = "assets/images/file-images/file-img-3.png";
  payslip = "assets/images/file-images/file-img-3.png";
  fieldObject: any;
  bankDetails: any;
  branchData: any;
  branchCode: any;
  isProcessing: boolean;
  phoneNumber: any = "";
  isPhoneOk: boolean;
  // selectedIndex = 0;
  passportPhotoFileName = null;
  residenceProofFileName = null;
  IDProofFileName = null;
  payslipFileName = null;
  pageTitile: any;
  public data;
  fileupload: any = [];

  private filterTitle() {
    if (!this.title) {
      return;
    }
    let search = this.titleFiltr.value;
    if (!search) {
      this.filteredtitle.next(this.title.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredtitle.next(
      this.title.filter(title => title.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterIdType() {
    if (!this.IDType) {
      return;
    }
    let search = this.idtypeFiltr.value;
    if (!search) {
      this.filteredidType.next(this.IDType.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredidType.next(
      this.IDType.filter(title => title.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filtercodes() {
    if (!this.countryCodes.allCountries) {
      return;
    }
    let search = this.codesFiltr.value;
    if (!search) {
      this.countryCodesFiltered.next(this.countryCodes.allCountries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.countryCodesFiltered.next(
      this.countryCodes.allCountries.filter(
        countryCodes => countryCodes[0].toLowerCase().indexOf(search) > -1
      )
    );
  }

  constructor(
    // private dialogRef: MatDialogRef<CustomerProfileSettingsComponent>,
    private userService: UserService,
    private router: Router,
    private fuseSplashScreen: FuseSplashScreenService,
    private feedbackService: FeedbackService,
    private bankService: BankService,
    private contactsService: ContactsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    this.pageTitile = this.route.snapshot.params["data"];
    this.currentUser = JSON.parse(localStorage.getItem("user")).user;
  }

  phoneValidation(event) {
    this.phoneNumber = event.target.value;
    this.isProcessing = true;
    this.userService.phoneValidationCheck(this.phoneNumber).subscribe(
      () => {
        this.isProcessing = false;
        this.isPhoneOk = true;
      },
      () => {
        this.isProcessing = false;
        this.isPhoneOk = false;
      }
    );
  }

  setStep(index: number) {
    this.step = index;
  }
  selectedIndexChange(val: number) {
    this.selectedIndex += val;
  }
  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngOnInit() {
    this.countryCodes = new CountryCode();
    this.currentUser = JSON.parse(localStorage.getItem("user")).user;
    this.getBankData();

    this.filteredtitle.next(this.title.slice());
    this.titleFiltr.valueChanges
      .pipe(takeUntil(this._ontitle))
      .subscribe(() => {
        this.filterTitle();
      });

    this.filteredidType.next(this.IDType.slice());
    this.idtypeFiltr.valueChanges
      .pipe(takeUntil(this._ontitle))
      .subscribe(() => {
        this.filterIdType();
      });

    this.countryCodesFiltered.next(this.countryCodes.allCountries.slice());
    this.codesFiltr.valueChanges
      .pipe(takeUntil(this._ontitle))
      .subscribe(() => {
        this.filtercodes();
      });
  }

  createCustomer() {
    let data;
    if (this.isNewCustomer) {
      data = null;
    } else {
      data = this.customerForm.value;
    }
  }

  rejectCustomer() {
    this.rejectClicked = true;
  }

  approveCustomer(approval) {
    if (approval === "approved") {
      console.log("customer Id: ", this.customerForm.getRawValue());
      this.userService
        .approveCustomer(
          this.customerForm.getRawValue().id,
          this.currentUser.id
        )
        .subscribe(r => {
          if (r.success) {
            this.feedbackService.show("Approved", "", "right", "top");
            this.router.navigate(["/auth/customers"]);
          } else {
            this.feedbackService.show(r.message, "", "right", "top");
          }
        });
    } else {
      const reason = approval;
      if (reason && reason.length > 0) {
        this.userService
          .rejectCustomer(
            this.customerForm.getRawValue().id,
            reason,
            this.currentUser.id
          )
          .subscribe(r => {
            if (r.success) {
              this.feedbackService.show("Rejected", "", "right", "top");
              this.router.navigate(["/auth/customers"]);
            } else {
              this.feedbackService.show(r.message, "", "right", "top");
            }
          });
      } else {
        this.showRejectError = true;
      }
    }

    // end
  }

  cancelRejection() {
    this.rejectReasonText = null;
    this.rejectClicked = false;
    this.showRejectError = false;
  }

  saveCustomer() {
    this.customerForm.controls["clerk"].setValue(this.currentUser.id);
    this.userService
      .createCustomer(this.customerForm.getRawValue())
      .subscribe(resp => {
        if (resp.success) {
          this.customerForm.controls["id"].setValue(resp.result.id);
          if (this.fileupload.length > 0) {
            this.postFile(resp.result.id);
          } else {
            this.snackBar.open("Successfully Created Customer", "OK", {
              duration: 5000,

              verticalPosition: this.verticalPosition
            });
            this.fuseSplashScreen.hide();
            this.router.navigate(["/auth/customers"]);
          }
        } else {
          this.snackBar.open("Something went wrong", "OK", {
            duration: 5000,

            verticalPosition: this.verticalPosition
          });
        }
      });
    this.fuseSplashScreen.hide();
  }

  attachmentUpload(event, docType) {
    const attachedFiles = event.target.files;
    if (attachedFiles) {
      const index = this.fileupload.findIndex(doc => doc.docType === docType);
      if (index > -1) {
        this.fileupload.splice(index, 1);
      }
      this.fileupload.push({ docType: docType, file: attachedFiles[0] });
      switch (docType) {
        case "attachmentOfProofOfResidence":
          this.residenceProofFileName = attachedFiles.item(0).name;
          break;
        case "attachmentOfID":
          this.IDProofFileName = attachedFiles.item(0).name;
          break;
        case "attachmentOfPassportSizePhoto":
          this.passportPhotoFileName = attachedFiles.item(0).name;
          break;
        case "attachmentOfPayslip":
          this.payslipFileName = attachedFiles.item(0).name;
          break;
      }
    }
    console.log(docType);
  }

  postFile(customerId) {
    const formData = new FormData();
    for (let i = 0; i < this.fileupload.length; i++) {
      formData.append(this.fileupload[i].docType, this.fileupload[i].file);
      /*
      this.userService
        .pushFileToStorage(this.fileupload[i].file, customerId, this.fileupload[i].docType, this.fileupload[i].docType)
        .subscribe(res => {
            let message = '';
            if (res instanceof HttpResponse) {
              message = 'Customer created successfully';
              this.router.navigate(['/auth/customers']);
            } else {
              message = "Error occured"
            }
            
            this.feedbackService.show(message, '', 'center', 'top');
            this.fuseSplashScreen.hide();
          }); */
    }

    this.userService
      .uploadCustomerAttachment(customerId, "", formData)
      .subscribe(res => {
        let message = "";
        if (res.success) {
          message = "Customer created successfully";
          this.router.navigate(["/auth/customers"]);
        } else {
          message = res.message;
        }
        this.feedbackService.show(message, "", "right", "top");
        this.fuseSplashScreen.hide();
      });
  }

  openInput(id) {
    document.getElementById(id).click();
  }

  attachmentDownload(type) {
    window.open(
      "http://localhost:3000/api/Customers/downloadCustomerFile?customerId=" +
      this.customerForm.controls["id"].value +
      "&fileType=" +
      type
    );
  }

  getBankData() {
    this.bankService.getAllBanks().subscribe(
      res => {
        this.bankDetails = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getBranches(event) {
    if (event) {
      const bankID = event;
      const filter = this.bankDetails.filter(data => data.id === bankID);
      this.branchData = filter[0].branches;
    }
  }

  getBranchCode(event) {
    if (event) {
      const branchID = event;
      const filter = this.branchData.filter(data => data.id === branchID);
      this.customerForm.patchValue({ bankBranch: filter[0].code });
    }
  }

  selectDisplayImage(url) {
    const isImageType = /\.(gif|jpg|jpeg|tiff|png)$/i.test(url);
    if (isImageType) {
      return url;
    }
    return "assets/images/document-xxl.png";
  }

  //New file upload Code
  /*

  selectFile(event, filename, uploadType) {
    this.selectedFiles = event.target.files;
    var reader = new FileReader();

    if (filename == "profilePicture") {
      this.user.avatar = event.target.files[0].name;
      reader.onload = (event: any) => {
        this.user.avatar = event.target.result;
      };
    }

    reader.readAsDataURL(event.target.files[0]);
    console.log(uploadType);
    this.upload(filename, uploadType);
  }

  upload(filename, uploadType) {
    console.log(uploadType);
    this.progress.percentage = 0;

    this.currentFileUpload = this.selectedFiles.item(0);
    this.userService
      .pushFileToStorage(
        this.currentFileUpload,
        this.user.customerId,
        filename,
        uploadType
      )
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(
            (100 * event.loaded) / event.total
          );
        } else if (event instanceof HttpResponse) {
          console.log(event.body);

          // this.user.avatar = "https://lupiya-backend-test.eu-gb.mybluemix.net" + JSON.parse(event.body)["profilePicture"]
        }
      });

    this.selectedFiles = undefined;
  }

  */
}
