import { Injectable } from '@angular/core';
import { CustomerStore } from '../customer-store';
import { Store } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';
import {
  CREATE_CORPORATE_CONTACT, CREATE_CORPORATE_CUSTOMER, CreateCorporateContactAction, CreateCorporateCustomerAction,
  DELETE_CORPORATE_CUSTOMER, DeleteAndReloadCorporateCustomerAction,
  DeleteCorporateCustomerAction,
  FETCH_CORPORATE_CUSTOMERS,
  FetchAllCorporateCustomersAction,
  LoadCorporateCustomersAction, LoadNewContactIdAction
} from '../actions/corporate-customer-actions';
import { switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../../services';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { FeedbackService } from '../feedback.service';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Injectable()
export class CorporateCustomerEffect {

  private horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private store: Store<CustomerStore>,
    private actions: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private fuseSplashScreen: FuseSplashScreenService ,
    private feedbackService: FeedbackService,
    private service: UserService) {
  }

  @Effect({ dispatch: false })
  fetchCorporateCustomers = this.actions.ofType<FetchAllCorporateCustomersAction>(FETCH_CORPORATE_CUSTOMERS).pipe(
    switchMap(action => this.service.getAllCorporateCustomers()),
    tap(res => {
      if (res.success) {
        this.store.dispatch(new LoadCorporateCustomersAction(res.results));
      } else {
        this.feedbackService.show(res.message, '', this.horizontalPosition, this.verticalPosition);
      }
    })
  );

  @Effect({ dispatch: false })
  deleteCorporateCustomer = this.actions.ofType<DeleteCorporateCustomerAction>(DELETE_CORPORATE_CUSTOMER).pipe(
    switchMap(action => this.service.deleteCorporateCustomer(action.payload)),
    tap((res: any) => {
      if (res.success) {
        this.store.dispatch(new DeleteAndReloadCorporateCustomerAction(res.id));
        this.feedbackService.show('Corporate customer deleted successfully', '', this.horizontalPosition, this.verticalPosition);
      } else {
        this.feedbackService.show(res.message, '', this.horizontalPosition, this.verticalPosition);
      }
    })
  );

  @Effect({ dispatch: false })
  createCorporateContact = this.actions.ofType<CreateCorporateContactAction>(CREATE_CORPORATE_CONTACT).pipe(
    switchMap(action => this.service.createCorporateContact(action.payload)),
    tap(res => {
      if (res['success']) {
        this.store.dispatch(new FetchAllCorporateCustomersAction());
        this.store.dispatch(new LoadNewContactIdAction(null));
        this.router.navigate(['/auth/corporate-customers']);
        this.feedbackService.show(res.message, 'success', this.horizontalPosition, this.verticalPosition);
      } else {
        this.feedbackService.show(res.message, 'Fail', this.horizontalPosition, this.verticalPosition);
      }
    })
  );

  @Effect({ dispatch: false })
  createCorporateCustomer = this.actions.ofType<CreateCorporateCustomerAction>(CREATE_CORPORATE_CUSTOMER).pipe(
    switchMap(action => this.service.createCorporateCustomer(action.payload)),
    tap(res => {
      if (res['success']) {
        this.service.companyForm.get('contact.corporate_customer_id').setValue(res.id); 
        this.store.dispatch(new CreateCorporateContactAction(this.service.companyForm.get('contact').value));            
       } else {
       this.feedbackService.show(res['message'],'fail', this.horizontalPosition, this.verticalPosition);   
       } 
    })
  );
}
