import {Action} from '@ngrx/store';

export const FETCH_ALL_CUSTOMERS_ACTION = 'FETCH_ALL_CUSTOMERS_ACTION';
export class FetchAllCustomersAction implements Action {
  readonly type: string = FETCH_ALL_CUSTOMERS_ACTION;
  constructor() {
  }
}

export const CREATE_CUSTOMER_ACTION = 'CREATE_CUSTOMER_ACTION';
export class CreateCustomerAction implements Action {
  readonly type: string = CREATE_CUSTOMER_ACTION;
  constructor(public payload: any) {
  }
}

export const LOAD_CUSTOMERS_ACTION = 'LOAD_CUSTOMERS_ACTION';
export class LoadCustomersAction implements Action {
  readonly type: string = LOAD_CUSTOMERS_ACTION;
  constructor(public payload: any[]) {
  }
}

export const DELETE_CUSTOMER_ACTION = 'DELETE_CUSTOMER_ACTION';
export class DeleteCustomerAction implements Action {
  readonly type: string = DELETE_CUSTOMER_ACTION;
  constructor(public payload: number) {
  }
}

export const DELETE_AND_LOAD_CUSTOMER_ACTION = 'DELETE_AND_LOAD_CUSTOMER_ACTION';
export class DeleteAndLoadCustomersAction implements Action {
  readonly type: string = DELETE_AND_LOAD_CUSTOMER_ACTION;
  constructor(public payload: number) {
  }
}
