import {CustomerStore} from '../customer-store';
import {DELETE_AND_LOAD_CUSTOMER_ACTION, DeleteAndLoadCustomersAction, LOAD_CUSTOMERS_ACTION, LoadCustomersAction} from '../actions/customer-actions';
import {
  DELETE_AND_RELOAD_CORPORATE_CUSTOMER,
  DeleteAndReloadCorporateCustomerAction,
  LOAD_CORPORATE_CUSTOMERS, LOAD_NEW_CONTACT_ID,
  LoadCorporateCustomersAction, LoadNewContactIdAction, SELECT_CORPORATE_CUSTOMER
} from '../actions/corporate-customer-actions';
import { promise } from 'protractor';


export type CustomerActions = LoadCustomersAction | DeleteAndLoadCustomersAction;
export type CorporateCustomerActions = LoadCorporateCustomersAction |
  DeleteAndReloadCorporateCustomerAction |
  LoadNewContactIdAction;

const defaultState: CustomerStore = {
  customers: {
    stored: [],
    selected: {}
  },
  corporateCustomers: {
    stored: [],
    selected: {}
  }
};

export function customerReducer(state = {stored: [], selected: {}}, action: CustomerActions) {
  switch (action.type) {
    case LOAD_CUSTOMERS_ACTION:
      return Object.assign({}, state, {stored: action.payload});
    case DELETE_AND_LOAD_CUSTOMER_ACTION:
      const afterDelete = state.stored.filter(s => s.id !== action.payload);
      return Object.assign({}, state, {stored: afterDelete});
    default:
      return state;
  }
}

export function corporateCustomerReducer(state = {stored: [], selected: {}}, action: CorporateCustomerActions) {
  switch (action.type) {
    case LOAD_CORPORATE_CUSTOMERS:
      return Object.assign({}, state, {stored: action.payload});
    case DELETE_AND_RELOAD_CORPORATE_CUSTOMER:
      const afterDelete = state.stored.filter(s => s.id !== action.payload);
      return Object.assign({}, state, {stored: afterDelete});
    case LOAD_NEW_CONTACT_ID:
      return Object.assign({}, state, {contactId: action.payload});
    case SELECT_CORPORATE_CUSTOMER:
      return Object.assign({}, state, {selected: action.payload});
    default:
      return state;
  }
}
