// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  serverURL: "https://online.longhorn-associates.com:3001/api",
  // serverURL: "https://longhorn.backend.apptorney.org/api",
  // serverURL: "https://longhorn-backend-test.eu-gb.mybluemix.net/api",
  // serverURL: "https://longhorn.backend.apptorney.org/api",
  // serverURL: "http://localhost:3000/api",
  // serverURL: "http://34.67.159.212:3000/api",

  baseURL: "http://localhost:4200",
  imageURL: "https://lmis.circuitbusiness.com",
};

// export const environment = {
//   production: false,
//   hmr       : false,
//   serverURL: 'http://35.184.111.134:3000/api',
//    baseURL: 'http://localhost:4200',
//    imageURL: 'http://0.0.0.0:3000'
// };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
