import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class ContactsService implements Resolve<any>
{
  // created by shahrukh
    customerDetails: any = {};
    corporateCustomer: any = [];
  // end

  timeline: any;
  about: any;
  photosVideos: any;

  timelineOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
  aboutOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
  photosVideosOnChanged: BehaviorSubject<any> = new BehaviorSubject({});
  private subject = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  sendMessage(foo) {
    this.subject.next({msg:foo});
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Resolve
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getTimeline(),
        this.getAbout(),
        this.getPhotosVideos()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get timeline
   */
  getTimeline(): Promise<any[]> {
    return new Promise((resolve, reject) => {

      this.http.get('api/profile-timeline')
        .subscribe((timeline: any) => {
          this.timeline = timeline;
          this.timelineOnChanged.next(this.timeline);
          resolve(this.timeline);
        }, reject);
    });
  }

  /**
   * Get about
   */
  getAbout(): Promise<any[]> {
    return new Promise((resolve, reject) => {

      this.http.get('api/profile-about')
        .subscribe((about: any) => {
          this.about = about;
          this.aboutOnChanged.next(this.about);
          resolve(this.about);
        }, reject);
    });
  }

  /**
   * Get photos & videos
   */
  getPhotosVideos(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get('api/profile-photos-videos')
        .subscribe((photosVideos: any) => {
          this.photosVideos = photosVideos;
          this.photosVideosOnChanged.next(this.photosVideos);
          resolve(this.photosVideos);
        }, reject);
    });
  }

}

















