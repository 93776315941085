import {Actions, Effect} from '@ngrx/effects';
import {Inject, Injectable} from '@angular/core';
import {
  CREATE_CUSTOMER_ACTION,
  CreateCustomerAction,
  DELETE_CUSTOMER_ACTION, DeleteAndLoadCustomersAction, DeleteCustomerAction,
  FETCH_ALL_CUSTOMERS_ACTION,
  FetchAllCustomersAction,
  LoadCustomersAction
} from '../actions/customer-actions';
import {switchMap, tap} from 'rxjs/operators';
import {UserService} from '../../../services';
import {Store} from '@ngrx/store';
import {CustomerStore} from '../customer-store';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';
import {FeedbackService} from '../feedback.service';
import {FuseSplashScreenService} from '../../../../../../@fuse/services/splash-screen.service';

@Injectable()
export class CustomerEffect {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private store: Store<CustomerStore>,
              private userService: UserService,
              private feedbackService: FeedbackService,
              private fuseSplashScreen: FuseSplashScreenService,
              private actions: Actions) {
  }

  @Effect({dispatch: false})
  fetchAllCustomers = this.actions.ofType<FetchAllCustomersAction>(FETCH_ALL_CUSTOMERS_ACTION).pipe(
    switchMap(action => {
      return this.userService.getAllCustomers();
    }),
    tap((customers: any) => {
      this.store.dispatch(new LoadCustomersAction(customers.result));
    }));

  @Effect({dispatch: false})
  createCustomer = this.actions.ofType<CreateCustomerAction>(CREATE_CUSTOMER_ACTION).pipe(
    switchMap(action => {
      return this.userService.createCustomer(action.payload);
    }),
    tap((customers: any) => {
      this.store.dispatch(new FetchAllCustomersAction());
    }));

  @Effect({dispatch: false})
  deleteCustomer = this.actions.ofType<DeleteCustomerAction>(DELETE_CUSTOMER_ACTION).pipe(
    switchMap(action => this.userService.deleteCustomer(action.payload)),
    tap((res: any) => {
      console.log(res);
      if (res.success) {
        this.store.dispatch(new DeleteAndLoadCustomersAction(res.result));
        this.feedbackService.show('Customer deleted successfully', '', this.horizontalPosition, this.verticalPosition);
      } else {
        this.feedbackService.show(res.message, '', this.horizontalPosition, this.verticalPosition);
        this.fuseSplashScreen.hide();
      }
    })
  );
}
