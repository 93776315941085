export const Clerk = [
  {id: 'system-navigation',
  title: 'System Navigation',
  type: 'group',
  icon: 'apps',
  children: [
  {
    'id': 'dashboard',
    'title': 'Dashboard',
    'icon': 'dashboard',
    'type': 'item',
    'url': 'auth/all-assets'
  },
  {
    id: 'transaction',
    title: 'Transactions',
    type: 'item',
    icon: 'library_books',
    url: 'auth/transaction'
  },
  {
    id: 'customer',
    title: 'Customers',
    type: 'item',
    icon: 'people',
    url: 'auth/customers'
  },
  {
    id: 'account',
    title: 'Accounts',
    type: 'item',
    icon: 'account_balance_wallet',
    url: 'auth/account'
  },
  {
    id: 'corp_customer',
    title: 'Corporate Customers',
    type: 'item',
    icon: 'work',
    url: 'auth/corporate-customers'
  }]},
  {
    id: 'fund-admin',
    title: 'Fund Administration',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'unit_trust',
        title: 'Unit trust',
        type: 'item',
        icon: 'apps',
        url: 'auth/unit-trusts'
      },
      {
        id: 'pension_fund',
        title: 'Pension Fund',
        type: 'item',
        icon: 'group',
        url: 'auth/pension-funds'
      }
    ]
  },
  {
    id: 'fund-management',
    title: 'Fund Management',
    type: 'group',
    children: [
      {
        id: 'fixed_deposit',
        title: 'Fixed Deposit Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
          
          {
            id: 'fixed_deposit_issuer',
            title: 'Counterparties',
            type: 'item',
            url: 'auth/list-view/fixedDepositIssuer'
          },
          {
            id: 'fixed_deposit_security',
            title: 'Security',
            type: 'item',
            url: 'auth/list-view/fixedDeposit'
          },
          {
            id: 'fixed_deposit_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/fixedDepositOrder'
          },
          {
            id: 'fixed_deposit_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/fixedDepositAsset'
          },
          {
            id: 'fixed_deposit_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/fixedDepositAssetValuation'
          }
        ]
       
      },
      {
        id: 'fixed_interest',
        title: 'Fixed Interest Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
          {
            id: 'fixed_interest_issuer',
            title: 'Counterparties',
            type: 'item',
            url: 'auth/list-view/issuer'
          },
          {
            id: 'fixed_interest_security',
            title: 'Security',
            type: 'item',
            url: 'auth/list-view/fixedInterestSecurity'
          },
          {
            id: 'fixed_interest_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/fixedInterestOrder'
          },
          {
            id: 'fixed_interest_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/fixedInterestSecurityAsset'
          },
          {
            id: 'fixed_interest_coupon',
            title: 'Coupons',
            type: 'item',
            url: 'auth/list-view/coupon'
          },
          {
            id: 'fixed_interest_redemption',
            title: 'Coupon Redemptions',
            type: 'item',
            url: 'auth/list-view/couponRedemption'
          },
          {
            id: 'fixed_interest_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/fixedInterestSecurityAssetValuation'
          }
        ]
       
      },
      {
        id: 'equity_securities',
        title: 'Equity Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
          {
            id: 'equity_sector',
            title: 'Sector',
            type: 'item',
            url: 'auth/list-view/sector'
          },
          {
            id: 'exchange',
            title: 'Exchanges',
            type: 'item',
            url: 'auth/list-view/exchange'
          },
          {
            id: 'equity_security',
            title: 'Securities',
            type: 'item',
            url: 'auth/list-view/equity'
          },
          {
            id: 'equity_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/equityOrder'
          },
          {
            id: 'equity_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/equityAsset'
          },
          {
            id: 'equity_ticker',
            title: 'Equity Ticker',
            type: 'item',
            url: 'auth/list-view/equityTicker'
          },
          {
            id: 'equity_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/equityAssetValuation'
          }
        ]

      },

      {
        id: 'real_estate_securities',
        title: 'Real Estate Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
    
          {
            id: 'real_estate_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/realEstateOrder'
          },
          {
            id: 'real_estate_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/realEstateAsset'
          },
          {
            id: 'real_estate_rent_contact',
            title: 'Rent Contract',
            type: 'item',
            url: 'auth/list-view/realEstateRentContract'
          },
          {
            id: 'real_estate_rent_payment',
            title: 'Rent Payment',
            type: 'item',
            url: 'auth/list-view/realEstateRentPayment'
          },
          {
            id: 'real_estate_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/realEstateAssetValuation'
          }
        ]

      }
    ]
  },
/*
  {
    id: 'analytics',
    title: 'Analytics',
    type: 'group',

    children: [
      {
        id: 'reports',
        title: 'Reports',
        type: 'collapse',
        icon: 'assessment',
        children: [
          {
            id: 'all-assets-report',
            title: 'All Assets',
            type: 'item',
            url: 'auth/all-assets'
          },
          {
            id: 'unit_trust_order',
            title: 'Unit Trust Orders',
            type: 'item',
            url: 'auth/unit-trust-orders'
          },
          {
            id: 'pension_fund_order',
            title: 'Pension Fund Orders',
            type: 'item',
            url: 'auth/pension-fund-orders'
          }
        ]
      }
    ]
  }
*/
];

export const Supervisor = [
  {
    id: 'system-navigation',
    title: 'System Navigation',
    type: 'group',
    icon: 'apps',
    children: [
  {
    'id': 'dashboard',
    'title': 'Dashboard',
    'icon': 'dashboard',
    'type': 'item',
    'url': 'auth/all-assets'
  },
  {
    id: 'transaction',
    title: 'Transactions',
    type: 'item',
    icon: 'library_books',
    url: 'auth/transaction'
  },
  {
    id: 'approve_customer',
    title: 'Customers',
    type: 'item',
    icon: 'person',
    url: 'auth/customers'
  },

  {
    id: 'account',
    title: 'Accounts',
    type: 'item',
    icon: 'account_balance_wallet',
    url: 'auth/account'
  },
  {
    id: 'approve_corp_customer',
    title: 'Corporate Customers',
    type: 'item',
    icon: 'work',
    url: 'auth/corporate-customers'
  }],
},

  {
    id: 'fund-admin',
    title: 'Fund Administration',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'unit_trust',
        title: 'Unit trust',
        type: 'item',
        icon: 'apps',
        url: 'auth/unit-trusts'
      },
      {
        id: 'pension_fund',
        title: 'Pension Fund',
        type: 'item',
        icon: 'group',
        url: 'auth/pension-funds'
      }
    ]
  },
  {
    id: 'fund-management',
    title: 'Fund Management',
    type: 'group',
    children: [
      {
        id: 'fixed_deposit',
        title: 'Fixed Deposit Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [

          {
            id: 'fixed_deposit_issuer',
            title: 'Counterparties',
            type: 'item',
            url: 'auth/list-view/fixedDepositIssuer'
          },
          {
            id: 'fixed_deposit_security',
            title: 'Security',
            type: 'item',
            url: 'auth/list-view/fixedDeposit'
          },
          {
            id: 'fixed_deposit_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/fixedDepositOrder'
          },
          {
            id: 'fixed_deposit_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/fixedDepositAsset'
          },
          {
            id: 'fixed_deposit_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/fixedDepositAssetValuation'
          }
        ]

      },
      {
        id: 'fixed_interest',
        title: 'Fixed Interest Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
          {
            id: 'fixed_interest_issuer',
            title: 'Counterparties',
            type: 'item',
            url: 'auth/list-view/issuer'
          },
          {
            id: 'fixed_interest_security',
            title: 'Security',
            type: 'item',
            url: 'auth/list-view/fixedInterestSecurity'
          },
          {
            id: 'fixed_interest_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/fixedInterestOrder'
          },
          {
            id: 'fixed_interest_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/fixedInterestSecurityAsset'
          },
          {
            id: 'fixed_interest_coupon',
            title: 'Coupons',
            type: 'item',
            url: 'auth/list-view/coupon'
          },
          {
            id: 'fixed_interest_redemption',
            title: 'Coupon Redemptions',
            type: 'item',
            url: 'auth/list-view/couponRedemption'
          },
          {
            id: 'fixed_interest_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/fixedInterestSecurityAssetValuation'
          }
        ]

      },
      {
        id: 'equity_securities',
        title: 'Equity Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
          {
            id: 'equity_sector',
            title: 'Sector',
            type: 'item',
            url: 'auth/list-view/sector'
          },
          {
            id: 'exchange',
            title: 'Exchanges',
            type: 'item',
            url: 'auth/list-view/exchange'
          },
          {
            id: 'equity_security',
            title: 'Security',
            type: 'item',
            url: 'auth/list-view/equity'
          },
          {
            id: 'equity_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/equityOrder'
          },
          {
            id: 'equity_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/equityAsset'
          },
          {
            id: 'equity_ticker',
            title: 'Equity Ticker',
            type: 'item',
            url: 'auth/list-view/equityTicker'
          },
          {
            id: 'equity_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/equityAssetValuation'
          }
        ]

      },

      {
        id: 'real_estate_securities',
        title: 'Real Estate Securities',
        type: 'collapse',
        icon: 'arrow_drop_down_circle',
        children: [
   
          {
            id: 'real_estate_order',
            title: 'Orders',
            type: 'item',
            url: 'auth/list-view/realEstateOrder'
          },
          {
            id: 'real_estate_asset',
            title: 'Assets',
            type: 'item',
            url: 'auth/list-view/realEstateAsset'
          },
          {
            id: 'real_estate_rent_contact',
            title: 'Rent Contract',
            type: 'item',
            url: 'auth/list-view/realEstateRentContract'
          },

          {
            id: 'real_estate_rent_payment',
            title: 'Rent Payment',
            type: 'item',
            url: 'auth/list-view/realEstateRentPayment'
          },

          {
            id: 'real_estate_valuation',
            title: 'Asset Valuation',
            type: 'item',
            url: 'auth/list-view/realEstateAssetValuation'
          }
        ]

      }
    ]
  },
  
  {
    id: 'analytics',
    title: 'Analytics',
    type: 'group',

    children: [
      {
        id: 'reports',
        title: 'Reports',
        type: 'collapse',
        icon: 'assessment',
        children: [
          {
            id: 'unit_trust_valuation',
            title: 'Unit Trust Valuation',
            type: 'item',
            url: 'auth/list-view/unitTrustValuation'
          },
          {
            id: 'unit_trust_fees',
            title: 'Unit Trust Fees',
            type: 'item',
            url: 'auth/list-view/unitTrustFees'
          }
        ]
      }
    ]
  }
  
];



export const Manager = [
  {
    'id': 'dashboard',
    'title': 'Dashboard',
    'icon': 'dashboard',
    'type': 'item',
    'url': 'auth/dashboard'
  },
  {
    id: 'transaction',
    title: 'Transaction',
    type: 'item',
    icon: 'money',
    url: 'auth/transaction'
  },
  {
    id: 'approve_customer',
    title: 'Customer',
    type: 'item',
    icon: 'person',
    url: 'auth/customers'
  },

  {
    id: 'account',
    title: 'Account',
    type: 'item',
    icon: 'person',
    url: 'auth/account'
  },
  {
    id: 'approve_corp_customer',
    title: 'Corporate Customer',
    type: 'item',
    icon: 'work',
    url: 'auth/corporate-customers'
  },
  {
    id: 'fund-admin',
    title: 'Fund Administration',
    type: 'group',
    icon: 'apps',
    children: [
  {
    id: 'unit_trust',
    title: 'Unit trust',
    type: 'item',
    icon: 'apps',
    url: 'auth/unit-trusts'
  },
  {
    id: 'pension_fund',
    title: 'Pension Fund',
    type: 'item',
    icon: 'group',
    url: 'auth/pension-funds'
  }]},
  {
    id: 'InvestmentSetup',
    title: 'Investment Setup',
    type: 'group',
    icon: 'trending_up',
    children: [
      {
        id: 'equity',
        title: 'Equity',
        icon:'business_center',
        type: 'item',
        url: '/auth/equity'
      },
      {
        id: 'money-markets',
        title: 'Money Markets',
        icon: 'local_atm',
        type: 'item',
        url: '/auth/money-markets'
      },
      {
        id: 'bonds',
        title: 'Bonds',
        icon: 'account_balance',
        type: 'item',
        url: '/auth/bonds'
      },
      {
        id: 'real-estate',
        title: 'Real Estate',
        icon: 'business',
        type: 'item',
        url: '/auth/real-estate'
      },
      {
        id: 'alt-investment',
        title: 'Alternative Investment',
        icon: 'call_split',
        type: 'item',
        url: '/auth/alt-investment'
      }
    ]
  },
];

export const Customer = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    type: 'item',
    url: 'auth/customer-dashboard'
  },
  {
    'id': 'transaction-history',
    'title': 'My Accounts',
    'icon': 'account_balance_wallet',
    'type': 'item',
    'url': 'auth/transaction'
  },
  {
    id: '404',
    title: 'Help Center',
    icon: 'help',
    type: 'item',
    url: 'auth/help'
  }
];

export const Admin = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    url: 'auth/profile'
  },
  {
    id: 'database',
    title: 'Database',
    type: 'collapse',
    icon: 'add_box',
    children: [
      {
        id: 'customer',
        title: 'Customer',
        type: 'item',
        icon: 'contacts',
        url: 'auth/customers'
      },
      {
        id: 'banks',
        title: 'Banks',
        type: 'item',
        icon: 'business',
        url: 'auth/banks'
      }
    ]
  },
  {
    id: 'UserManagement',
    title: 'User Management',
    type: 'collapse',
    icon: 'shopping_cart',
    children: [
      {
        id: 'roles',
        title: 'User Roles',
        type: 'item',
        icon: 'group_add',
        url: 'auth/roles'
      },
      {
        id: 'Users',
        title: 'Users',
        type: 'item',
        icon: 'person',
        url: 'auth/Users'
      }
    ]
  }
];
