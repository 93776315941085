import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { FuseMainModule } from './main/main.module';
// import { AppStoreModule } from './store/store.module';
import { UserService, AuthGuard } from './main/content/services';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BankService } from './main/content/services/bank.service';
import {StoreModule} from '@ngrx/store';
import {StoreDevtools, StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {CustomerEffect} from './main/content/pages/customers/effects/customer-effect';
import {FeedbackService} from './main/content/pages/customers/feedback.service';
import {MatSnackBarModule} from '@angular/material';
import {FuseCustomerModule} from './main/content/pages/customers/customers.module';
import {DashboardModule} from './main/content/pages/dashboard/dashboard.module';
import {CustomerAccountService} from './main/content/services/customeraccount.service';
const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './main/content/pages/pages.module#FusePagesModule'
  },
  {
    path: 'services',
    loadChildren: './main/content/services/services.module#FuseServicesModule'
  },
  {
    path: '**',
    redirectTo: 'auth/profile'
  }
];

@NgModule({
  declarations: [
    AppComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),


    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,

    // AppStoreModule,
    FuseMainModule,
    // Telephone input country code
    BsDropdownModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    NgxIntlTelInputModule,
    FuseCustomerModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [UserService, AuthGuard, BankService, FeedbackService, CustomerAccountService],
})
export class AppModule {
}
