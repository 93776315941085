import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSort,
  MatTableDataSource,
  PageEvent
} from "@angular/material";
import { Subscription } from "rxjs";
import { UserService } from "../../../services/user.service";

import { fuseAnimations } from "@fuse/animations";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";

import { ContactsService } from "../customers.service";

import { Router } from "@angular/router";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";

import { Store } from "@ngrx/store";
import { CustomerStore } from "../customer-store";
import { DeleteCustomerAction } from "../actions/customer-actions";
import { customerSelector } from "../customer-selector";
import { Overlay } from "@angular/cdk/overlay";
import { FeedbackService } from "../feedback.service";

@Component({
  selector: "fuse-contacts-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FuseContactsCustomerListComponent
  implements OnInit, OnDestroy, AfterViewInit {
  status = this.userService.status;
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  data: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  subscription: Subscription;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  pageEvent: PageEvent;
  currentUser: any;
  rowDef = [];
  headerRowDef = [];

  @Output() onCustomerSelect = new EventEmitter<String>();
  allSelected: any;

  constructor(
    private contactsService: ContactsService,
    public dialog: MatDialog,
    public overlay: Overlay,
    private userService: UserService,
    private router: Router,
    private fuseSplashScreen: FuseSplashScreenService,
    public feedbackService: FeedbackService,
    private store: Store<CustomerStore>
  ) {
    this.fuseSplashScreen.show();
    this.currentUser = JSON.parse(localStorage.getItem("user")).user;
    if (this.currentUser.role.name === "clerk") {
      this.rowDef = ["firstName", "emailAddress", "phoneNumber", "buttons"];
      this.headerRowDef = [
        "firstName",
        "emailAddress",
        "phoneNumber",
        "buttons"
      ];
    } else {
      this.rowDef = [
        "selected",
        "firstName",
        "emailAddress",
        "phoneNumber",
        "status",
        "buttons"
      ];
      this.headerRowDef = [
        "selected",
        "firstName",
        "emailAddress",
        "phoneNumber",
        "status",
        "buttons"
      ];
    }
  }

  selectCustomer(_customer) {
    console.log(_customer.selected);
    this.onCustomerSelect.emit(_customer);
  }

  ngOnInit() {
    this.fetchAllCustomers();
  }

  private fetchAllCustomers() {
    this.fuseSplashScreen.show();
    this.store.select(customerSelector).subscribe((c: any) => {
      this.data = new MatTableDataSource<any>(c.stored);
      this.data.paginator = this.paginator;
      this.data.sort = this.sort;
      this.data.lenght = c.stored.length;
      this.fuseSplashScreen.hide();
    });
  }

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
    this.data.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.data.filter = filterValue;
  }

  viewCustomer(customer) {
    console.log("customer", customer);
    this.contactsService.customerDetails = customer;
    this.userService.customerForm.patchValue(customer);
    this.userService.customerForm.disable();
    this.router.navigate(["/auth/customers-form", { data: "Details" }]);
  }

  openSettings(customer) {
    this.userService.customerForm.enable();
    this.userService.customerForm.patchValue(customer);
    this.router.navigate(["/auth/customers-form", { data: "Update" }]);
  }

  deleteContact(contact) {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want to delete?";

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fuseSplashScreen.show();
        this.store.dispatch(new DeleteCustomerAction(contact.id));
      }
      this.confirmDialogRef = null;
    });
  }

  ngOnDestroy() {}

  selectAllCustomers() {
    if (this.allSelected) {
      console.log("*", this.data.filteredData);
      this.data.filteredData.forEach(customer => {
        customer.selected = false;
        this.onCustomerSelect.emit(customer);
      });
      this.allSelected = false;
    } else {
      console.log("*", this.data.filteredData);
      this.data.filteredData.forEach(customer => {
        customer.selected = true;
        this.onCustomerSelect.emit(customer);
      });
      this.allSelected = true;
    }
  }
}
