import { Injectable, OnInit } from "@angular/core";
import { RequestOptions, Headers, URLSearchParams } from "@angular/http";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpEvent,
  HttpRequest
} from "@angular/common/http";
import { Router } from "@angular/router";
import "rxjs/add/operator/map";
import { Observable, of } from "rxjs";
import { Response } from "@angular/http";
import { environment } from "../../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { BackendCommonUtil } from "./backend-common.util";
import { MatSnackBar } from "@angular/material";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";

@Injectable()
export class UserService implements OnInit {
  currentBalance: any;
  customerDetails: any;
  accountid: any;
  accountNumber: string;

  status = [
    {
      color: { "background-color": "primary", color: "white" },
      status: "Pending"
    },
    {
      color: { "background-color": "accent", color: "white" },
      status: "Approved"
    },
    {
      color: { "background-color": "warn", color: "white" },
      status: "Reject"
    }
  ];
  // token:string=localStorage.getItem('token');
  customerForm: FormGroup;
  companyForm: FormGroup;

  private header;
  constructor(
    private _http: HttpClient,
    private snackBar: MatSnackBar,
    private fuseSplashScreen: FuseSplashScreenService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.customerForm = this.fb.group({
      id: [""],
      title: ["", Validators.required],
      firstName: ["", Validators.required],
      middleName: ["", Validators.required],
      lastName: ["", Validators.required],
      DOB: ["", Validators.required],
      gender: ["", Validators.required],
      nationality: ["", Validators.required],
      nationalIDCardNumber: ["", Validators.required],
      IDType: ["", Validators.required],
      emailAddress: [
        "",
        Validators.compose([Validators.required, Validators.email])
      ],
      phoneNumber: ["", Validators.required],
      occupation: ["", Validators.required],
      employeeNumber: ["", Validators.required],
      employer: ["", Validators.required],
      sourceOfIncome: ["", Validators.required],
      totalIncome: ["", Validators.required],
      netIncome: ["", Validators.required],
      bank: [""],
      bankAccountNumber: ["", Validators.required],
      bankAccountName: ["", Validators.required],
      bankBranch: [""],
      taxIdNumber: ["", Validators.required],
      nextOfKin: ["", Validators.required],
      nextOfKinPhone: ["", Validators.required],
      relationshipWithNextOfKin: ["", Validators.required],
      salesPerson: ["", Validators.required],
      clerk: ["", Validators.required],
      supervisor: ["", Validators.required],
      customerPassword: ["", Validators.required],
      confirmCustomerPassword: ["", Validators.required],
      complete: ["", Validators.required],
      attachmentOfID: [""],
      attachmentOfProofOfResidence: [""],
      attachmentOfPayslip: [""],
      attachmentOfPassportSizePhoto: [""],
      relationshipManager: [""],
      relationshipManagerNRC: [""],
      fundName: [""],
      fundNumber: [""],
      contributionAmount: [""],
      contributionMethod: [""],
      commencementDate: [""],
      transferDate: [""],
      homeAddress: [""],
      postalAddress: [""]
    });

    this.companyForm = this.fb.group({
      id: [null],
      company_name: ["", Validators.required],
      country: ["", Validators.required],
      date_of_registration: ["", [Validators.required]],
      sector: [""],
      company_regisration_number: ["", Validators.required],
      bank: [""],
      bank_account_number: ["", Validators.required],
      bank_account_name: ["", Validators.required],
      bank_branch: [""],
      tax_id_number: ["", Validators.required],
      sales_person: ["", Validators.required],
      clerk: ["", Validators.required],
      relationshipManager: [""],
      relationshipManagerNRC: [""],
      contact: this.fb.group({
        corporate_customer_id: [""],
        id: [null],
        title: ["", Validators.required],
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        phone_number: ["", Validators.required],
        password: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]]
      })
    });

    this.setupHeader();
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getHttp_Header() {
    const httpOptions = {
      headers: new HttpHeaders({
        access_token: this.getToken()
      })
    };
    return httpOptions;
  }

  postHttp_Header() {
    const httpOptions = {
      headers: new HttpHeaders({
        access_token: this.getToken(),
        "Content-Type": "application/json"
      })
    };
    return httpOptions;
  }

  Get_Data(url: any) {
    return new Promise((resolve, reject) => {
      this._http
        .get(environment.serverURL + url, { headers: this.header })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    }).catch(err => {
      // this.snackBar.open('error occured! Please try again', '', {
      //   duration: 1000,
      //   horizontalPosition: this.horizontalPosition,
      //   verticalPosition: this.verticalPosition
      // });
    });
  }

  Post_Data(url: any, body: any) {
    return new Promise((resolve, reject) => {
      this._http
        .post(environment.serverURL + url, body, { headers: this.header })
        .subscribe(
          res => {
            console.log("corporate", res);
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    }).catch(err => {
      // this.snackBar.open('error occured! Please try again', '', {
      //   duration: 1000,
      //   horizontalPosition: this.horizontalPosition,
      //   verticalPosition: this.verticalPosition
      // });
    });
  }

  Patch_Data(url: any, body: any) {
    return new Promise((resolve, reject) => {
      this._http
        .patch(environment.serverURL + url, body, { headers: this.header })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    }).catch(err => {
      // this.snackBar.open('error occured! Please try again', '', {
      //   duration: 1000,
      //   horizontalPosition: this.horizontalPosition,
      //   verticalPosition: this.verticalPosition
      // });
    });
  }

  ngOnInit() { }

  /**
   * Register  user
   *
   * @param {any} user - user detail
   *
   * @returns added user details with _id
   */
  register(url: any, user: any): any {
    return this._http
      .post(environment.serverURL + url, user)
      .map((res: Response) => {
        return res;
      });
  }

  /**
   * Confirm Phone number
   */
  confirmPhoneOtp(data) {
    return this._http
      .get(
        environment.serverURL +
        "/appusers/confirmPhone?username=" +
        data.username +
        "&token=" +
        data.token
      )
      .map((res: Response) => {
        return res;
      });
  }

  /***
   * Login User account
   * @param {any} loginData - Username & password
   *
   * @returns user details
   */
  login(loginData) {
    return this._http
      .post(environment.serverURL + "/appusers/login?include=user", loginData)
      .map((res: Response) => {
        return res;
      });
  }

  /***
   * Reset Password of User
   */
  resetPassword(resetData) {
    return this._http
      .post(
        environment.serverURL +
        "/reset-password?access_token=" +
        resetData.access_token,
        { password: resetData.password, confirmation: resetData.password }
      )
      .map((res: Response) => {
        return res;
      });
  }

  /**
   * Logout User
   */
  logout() {
    localStorage.removeItem("user");
    this.router.navigate(["auth/login"]);
  }
  Profile() {
    this.router.navigate(["auth/profile"]);
  }
  /**
   * Forget the password
   *
   * @param {any} email - user email
   *
   */
  forgetPassword(emails) {
    var data = { email: emails, location: environment.baseURL };
    return this._http
      .post(environment.serverURL + "/appusers/reset", data)
      .map((res: Response) => {
        return res;
      });
  }

  /**
   * Phone Valdation Check
   *
   * @param {number} num - phone number with country code
   *
   * @returns is valid or not.
   */
  phoneValidationCheck(num: number) {
    return this._http
      .get(environment.serverURL + "/appusers/checkNumber?phoneNumber=" + num)
      .map((res: Response) => {
        return res;
      });
  }

  /**
   * upload attachment
   *
   * @param {any} data - attachment file
   *
   * @returns object with attachment _id and path.
   */
  attachmentUpload(data): any {
    return this._http
      .post(environment.serverURL + "/files/upload", data)
      .map(res => {
        return res;
      });
  }

  pushFileToStorage(
    file: File,
    customerId,
    filename,
    type
  ): Observable<HttpEvent<{}>> {
    console.log(filename);
    let formdata: FormData = new FormData();

    formdata.append("file", file);
    formdata.append("Id", customerId);
    formdata.append("type", type);
    formdata.append("filename", filename);

    const req = new HttpRequest(
      "POST",
      "http://localhost:3000/api/files/upload",
      formdata,
      {
        reportProgress: true,
        responseType: "text",
        headers: this.header
      }
    );

    return this._http.request(req);
  }

  uploadCustomerAttachment(customerId, docType, data): Observable<any> {
    return this._http
      .post(
        environment.serverURL +
        "/customers/uploadDocument?customerId=" +
        customerId +
        "&fileType=" +
        docType,
        data,
        { headers: this.header }
      )
      .pipe(
        map(res => res),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  uploadCorporateCustomerAttachment(
    customerId,
    docType,
    data
  ): Observable<any> {
    return this._http
      .post(
        environment.serverURL +
        "/corporateCustomers/uploadDocument?corporateCustomerId=" +
        customerId +
        "&fileType=" +
        docType,
        data,
        { headers: this.header }
      )
      .pipe(
        map(res => res),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  /**
   * Save or Update user Profile
   *
   * @param {any} data - Profile detail
   * @param {any} customerId - customer id
   *
   * @returns updated or added record details
   */
  saveProfile(data, customerId) {
    // var getUserData = localStorage.getItem('user');
    // var parseUserData = JSON.parse(getUserData);
    // var id = parseUserData.user.customerId;
    // console.log(data);
    if (customerId == "new") {
      return this._http
        .post(environment.serverURL + "/customers/", data)
        .map(res => {
          // console.log(res)
          return res;
        });
    } else {
      return this._http
        .put(environment.serverURL + "/customers/" + customerId, data)
        .map(res => {
          // console.log(res)
          return res;
        });
    }
  }

  /**
   * get profile data
   *
   *
   * @returns {object} profile data
   */
  getProfileData(customerId): any {
    var getUserData = localStorage.getItem("user");
    var parseUserData = JSON.parse(getUserData);
    var id = parseUserData.user.customerId;
    return this._http
      .get(environment.serverURL + "/customers/" + customerId)
      .map(res => {
        return res;
      });
  }

  /**
   * get profile data
   *
   *
   * @returns {object} profile data
   */
  getCustomerDetails(customerId?): any {
    var id = "";
    if (customerId) {
      id = customerId;
    } else {
      var getUserData = localStorage.getItem("user");
      var parseUserData = JSON.parse(getUserData);
      id = parseUserData.user.customerId;
    }
    return this._http
      .get(environment.serverURL + "/customers/" + id)
      .map(res => {
        return res;
      });
  }

  createCustomer(cust): Observable<any> {
    const req = {
      id: cust.id ? cust.id : null,
      title: cust.title,
      firstName: cust.firstName,
      middleName: cust.middleName,
      lastName: cust.lastName,
      DOB: cust.DOB,
      gender: cust.gender,
      countryCodeData: cust.personalDetailsNationality,
      nationalIDCardNumber: cust.nationalIDCardNumber,
      IDType: cust.IDType,
      emailAddress: cust.emailAddress,
      phoneNumber: cust.phoneNumber,
      occupation: cust.occupation,
      nationality: cust.nationality,
      employeeNumber: cust.employeeNumber,
      employer: cust.employer,
      sourceOfIncome: cust.sourceOfIncome,
      totalIncome: cust.totalIncome,
      netIncome: cust.netIncome,
      bank: cust.bank,
      bankAccountNumber: cust.bankAccountNumber,
      bankAccountName: cust.bankAccountName,
      bankBranch: cust.bankBranch,
      TaxIDNumber: cust.taxIdNumber,
      nextOfKin: cust.nextOfKin,
      nextOfKinPhone: cust.nextOfKinPhone,
      relationshipWithNextOfKin: cust.relationshipWithNextOfKin,
      salesPerson: cust.salesPerson,
      clerk: cust.clerk,
      supervisor: cust.supervisor,
      password: cust.customerPassword,
      passwordConfirm: cust.confirmCustomerPassword,
      complete: cust.complete,
      selfRegistration: false
    };
    if (req.id) {
      return this._http
        .patch(environment.serverURL + "/customers/" + req.id, req, {
          headers: this.header
        })
        .pipe(
          map((res: any) => {
            const newResp = {
              success: true,
              result: res
            };
            return BackendCommonUtil.createSuccessfulResponse(
              newResp,
              r => r.result
            );
          }),
          catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
        );
    } else {
      return this._http
        .post(environment.serverURL + "/customers/", req, {
          headers: this.header
        })
        .pipe(
          map((res: any) => {
            const newResp = {
              success: true,
              result: res
            };
            return BackendCommonUtil.createSuccessfulResponse(
              newResp,
              r => r.result
            );
          }),
          catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
        );
    }
  }

  /**
   * get All customers
   *
   *
   * @returns {any} list of all customers
   */
  getAllCustomers(): any {
    this.setupHeader();
    return this._http
      .get(environment.serverURL + "/customers/", { headers: this.header })
      .pipe(
        map((res: any) =>
          BackendCommonUtil.createSuccessfulResponseForArray(
            res,
            this.adaptCustomer
          )
        ),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  approveCustomer(customerId, supervisor) {
    const req = {
      customerId: customerId,
      supervisor_id: supervisor
    };
    return this._http
      .post(environment.serverURL + "/customers/approveCustomer/", req, {
        headers: this.header
      })
      .pipe(
        map(res => BackendCommonUtil.createSuccessfulResponse(res, r => r)),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  approveCustomers(customerIds, supervisor) {
    const req = {
      customerIds: customerIds,
      supervisor_id: supervisor
    };
    return this._http
      .post(environment.serverURL + "/customers/approveCustomers/", req, {
        headers: this.header
      })
      .pipe(
        map(res => BackendCommonUtil.createSuccessfulResponse(res, r => r)),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  rejectCustomer(customerId, rejectReason, supervisor) {
    const req = {
      customerId: customerId,
      reason: rejectReason,
      supervisor_id: supervisor
    };
    return this._http
      .post(environment.serverURL + "/customers/rejectCustomer/", req, {
        headers: this.header
      })
      .pipe(
        map(res => BackendCommonUtil.createSuccessfulResponse(res, r => r)),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  private adaptCustomer(cust: any[]) {
    return cust.map(c => {
      return {
        id: c.id,
        title: c.title,
        firstName: c.firstName,
        middleName: c.middleName,
        lastName: c.lastName,
        DOB: c.DOB,
        gender: c.gender,
        nationalIDCardNumber: c.nationalIDCardNumber,
        nationality: c.nationality,
        IDType: c.IDType,
        emailAddress: c.emailAddress,
        phoneNumber: c.phoneNumber,
        occupation: c.occupation,
        employeeNumber: c.employeeNumber,
        employer: c.employer,
        sourceOfIncome: c.sourceOfIncome,
        totalIncome: c.totalIncome,
        netIncome: c.netIncome,
        bank: c.bank,
        bankAccountNumber: c.bankAccountNumber,
        bankAccountName: c.bankAccountName,
        bankBranch: c.bankBranch,
        TaxIDNumber: c.TaxIDNumber,
        nextOfKin: c.nextOfKin,
        nextOfKinPhone: c.nextOfKinPhone,
        relationshipWithNextOfKin: c.relationshipWithNextOfKin,
        salesPerson: c.salesPerson,
        clerk: c.clerk,
        supervisor: c.supervisor,
        complete: c.complete,
        attachmentOfID: c.attachmentOfID,
        attachmentOfProofOfResidence: c.attachmentOfProofOfResidence,
        attachmentOfPayslip: c.attachmentOfPayslip,
        attachmentOfPassportSizePhoto: c.attachmentOfPassportSizePhoto,
        approvalStatus:
          c.approval_status === 0
            ? "Pending"
            : c.approval_status === 1
              ? "Approved"
              : "Rejected",
        countryCodeData: c.countryCodeData,
        relationshipManager: c.salesPerson,
        relationshipManagerNRC: c.salesPersonIDNumber,
        fundName: c.fundName,
        fundNumber: c.fundNumber,
        contributionAmount: c.contributionAmount,
        contributionMethod: c.contributionMethod,

        commencementDate: c.commencementDate,
        transferDate: c.transferDate,
        homeAddress: c.homeAddress,
        postalAddress: c.postalAddress

      };
    });
  }

  getAllCorporateCustomers(): Observable<any[]> | Observable<any> {
    this.setupHeader();
    return this._http
      .get(environment.serverURL + "/corporateCustomers/", {
        headers: this.header
      })
      .pipe(
        map(r => {
          return {
            success: true,
            results: r
          };
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            localStorage.clear();
            this.router.navigate(["/auth/login"]);
          }
          return of({ success: false, message: err.message });
        })
      );
  }

  private mapCorporateCustomer(r) {
    return r.map(singleResp => {
      return {
        companyName: singleResp.company_name,
        country: singleResp.country, // | singleResp.country,
        dateOfRegistration: singleResp.dateOfRegistration,
        sector: singleResp.sector,
        companyRegistrationNumber: singleResp.companyRegistrationNumber,
        bank: singleResp.bank,
        bankAccountNumber: singleResp.bankAccountNumber,
        bankAccountName: singleResp.bankAccountName,
        bankBranch: singleResp.bankBranch,
        taxIdNumber: singleResp.taxIdNumber,
        contactId: singleResp.contactId,
        salesPerson: singleResp.salesPerson,
        clerk: singleResp.clerk,
        supervisor: singleResp.supervisor,
        complete: singleResp.complete,
        approvalStatus: singleResp.approval_status,
        id: singleResp.id,
        contact: { ...singleResp.contact }
      };
    });
  }

  public approveCorporateCustomer(id, supervisor): Observable<any> {
    const body = {
      customerId: id,
      supervisor_id: supervisor
    };
    return this._http
      .post(
        environment.serverURL + "/corporateCustomers/approveCorporateCustomer/",
        body,
        { headers: this.header }
      )
      .pipe(
        map(res => BackendCommonUtil.createSuccessfulResponse(res, r => r)),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  public rejectCorporateCustomer(id, supervisor, reason): Observable<any> {
    const body = {
      customerId: id,
      rejectResone: reason,
      supervisor_id: supervisor
    };
    return this._http
      .post(
        environment.serverURL + "/corporateCustomers/rejectCorporateCustomer/",
        body,
        { headers: this.header }
      )
      .pipe(
        map(res => BackendCommonUtil.createSuccessfulResponse(res, r => r)),
        catchError(err => BackendCommonUtil.handleHTTPError(err, this.router))
      );
  }

  /**
   * Delete Customer
   * @param {any} id - customer id
   *
   * @returns delete cutomer confirmation.
   */
  deleteCustomer(id): any {
    return this._http
      .delete(environment.serverURL + "/customers/" + id, {
        headers: this.header
      })
      .pipe(
        map(res => {
          const newResp = {
            success: true,
            id: id
          };
          return BackendCommonUtil.createSuccessfulResponse(newResp, r => id);
        }),
        catchError((e: HttpErrorResponse) =>
          BackendCommonUtil.handleHTTPError(e, this.router)
        )
      );
  }

  deleteCorporateCustomer(id): Observable<any> {
    return this._http
      .delete(environment.serverURL + "/corporateCustomers/" + id, {
        headers: this.header
      })
      .pipe(
        map(res => {
          return {
            success: true,
            id: id
          };
        }),
        catchError(err =>
          of({
            success: false,
            message: err.message
          })
        )
      );
  }

  createCorporateContact(contact: any): Observable<any> {
    const req = {
      first_name: contact.first_name,
      last_name: contact.last_name,
      title: contact.title,
      phone_number: contact.phone_number,
      email: contact.email,
      password: contact.password,
      corporateCustomerId: contact.corporate_customer_id
    };
    this.fuseSplashScreen.show();
    if (!contact.id) {
      return this._http
        .post(environment.serverURL + "/contacts/addContact/", req, {
          headers: this.header
        })
        .pipe(
          map((res: any) => {
            this.fuseSplashScreen.hide();
            return res;
          }),
          catchError(err => {
            this.fuseSplashScreen.hide();
            return of({ success: false, message: err.message });
          })
        );
    } else {
      this.fuseSplashScreen.hide();
      return this._http
        .patch(environment.serverURL + "/contacts/" + contact.id, req, {
          headers: this.header
        })
        .pipe(map(res => res));
    }
  }

  createCorporateCustomer(customer: any): Observable<any> {
    const corporateCustomer = {
      company_name: customer.company_name,
      country: customer.country,
      date_of_registration: customer.date_of_registration,
      sector: customer.sector,
      company_regisration_number: customer.company_regisration_number,
      bank: customer.bank,
      bank_account_number: customer.bank_account_number,
      bank_account_name: customer.bank_account_name,
      bank_branch: customer.bank_branch,
      tax_id_number: customer.tax_id_number,
      clerk: customer.clerk,
      approval_status: 0
    };
    this.fuseSplashScreen.show();
    if (!customer.id) {
      return this._http
        .post(
          environment.serverURL + "/corporateCustomers/",
          corporateCustomer,
          { headers: this.header }
        )
        .pipe(
          map(res => {
            this.fuseSplashScreen.hide();
            return res;
          }),
          catchError(err => {
            this.fuseSplashScreen.hide();
            return of({ success: false, message: err.message });
          })
        );
    } else {
      this.fuseSplashScreen.hide();
      return this._http
        .patch(
          environment.serverURL + "/corporateCustomers/" + customer.id,
          corporateCustomer,
          { headers: this.header }
        )
        .pipe(map(res => res));
    }
  }

  /**
   * Get all banks
   * @returns {any} list of banks.
   */
  getbank(): any {
    return this._http
      .get(environment.serverURL + "/bank", { headers: this.header })
      .map(res => {
        return res;
      });
  }

  /**
   *  RoleMapping
   **/
  roleMapping(foo) {
    return this._http
      .post(environment.serverURL + "/RoleMappings", foo)
      .map(res => {
        return res;
      });
  }

  /**
   * Appuser Update role
   * @param {any} foo - All detail of Appuser with new role _id
   *
   * @returns Appuser Details
   **/
  roleUpdate(foo) {
    return this._http
      .put(
        environment.serverURL +
        "/appusers/" +
        foo.userId +
        "?access_token=" +
        foo.token,
        { roleId: foo.roleId }
      )
      .map(res => {
        return res;
      });
  }

  private setupHeader() {
    const loggedInUser = localStorage.getItem("user");
    if (!loggedInUser) {
      this.router.navigate(["/auth/login"]);
    } else {
      const token = JSON.parse(loggedInUser).id;
      this.header = new HttpHeaders({ access_token: token });
    }
  }
}
