import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Jsonp } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Customer } from '../pages/customeraccount/customer.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CustomerAccountService {

  readonly rootUrl = environment.serverURL;
  private customer = new Customer();
  private header;
  constructor(private _http: HttpClient) {
    const token = 'nm8vpkgJX6JTZAR15nVyWgXSwWKNF57ZAqdHtNqJI88slJBkkfzr1c8ueKGvTqOF';
    this.header = new HttpHeaders({'Authorization': token});
   }
  private handleError(error: Response) {
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }



  InsertSetting(data ) {


    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'nm8vpkgJX6JTZAR15nVyWgXSwWKNF57ZAqdHtNqJI88slJBkkfzr1c8ueKGvTqOF'
    });
    return this._http.post(environment.serverURL + '/customerTransactionAccounts/', data, { headers: this.header }).map(
      res => {
        // console.log(res)
        return res;
      }
    );
    // return this.http.post(this.rootUrl + '/customerTransactionAccounts' , data, { headers: reqHeader });
    // .map((response: Response) => response.json())
    // .catch(this.handleError);
  }

  UpdateSetting(data, customerID) {

    return this._http.post(environment.serverURL + '/customerTransactionAccounts/', data, this.header).map(
      res => {
        // console.log(res)
        return res;
      }
    );
    // var reqHeader = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'nm8vpkgJX6JTZAR15nVyWgXSwWKNF57ZAqdHtNqJI88slJBkkfzr1c8ueKGvTqOF'
    // });
    // return this.http.put(this.rootUrl + '/customerTransactionAccounts' + customerID, data, { headers: reqHeader });
    // .map((response: Response) => response.json())
    // .catch(this.handleError);
  }

}
