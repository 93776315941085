import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';

@Injectable()
export class FeedbackService {
  constructor(private snackBar: MatSnackBar) {
  }

  public show(message: string, action: string, hPosition: MatSnackBarHorizontalPosition, vPosition: MatSnackBarVerticalPosition) {
    this.snackBar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000
    });
  }

}
